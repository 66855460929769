/*
Project: Metro Housing Boston Property Manager Payment Portal
Developer:Juan Andujar
Date: Feb 2024

Notes: 

Pending Changes


*/


//CSS, Boostrap, React Components 
import React, { useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from '@aws-amplify/auth';
import { setAuthToken } from './axios.js';
import './css/App.css';
import './css/Title.css';
import './css/Footer.css';
import './css/NavMenu.css';
import './css/Payments.css';
import './css/LoggedInNav.css';
import './css/Public.css';

import 'bootstrap/dist/css/bootstrap.min.css';

//AWS Cognito, Authenticate, Amplify Components 
import { Amplify } from 'aws-amplify'
import { awsExports } from './aws-exports'; //connects to the cognito user pool

import '@aws-amplify/ui-react/styles.css';

//pages
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import { PublicPage } from './pages/public.js';
import { ProtectedPage } from './pages/index.js';
import { UserPage } from './pages/UserPage.js';
import { RegisterPage } from './pages/Register.js';
import { UserSettings } from './pages/UserSettings.js';
import { PaymentsPage } from './pages/Payments.js';
import { Documents } from './pages/Documents.js';
import { FaqPage } from './pages/FaqPage.js';


//configuration for our cognito user pool functionality . Its specifically linked with the region, the cognito pool or instance, and the app that is being run 
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
});
  
export default function App() {

  const fetchAuthToken = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      setAuthToken(token);
    } catch (error) {
      console.log('Error fetching JWT token:', error);
    }
  };

  const handleAuthStateChange = (state) => {
    if (state === 'signedIn') {
      fetchAuthToken();
    }
  };

  useEffect(() => {
    fetchAuthToken();
  }, []);
  
  return (
    <Authenticator.Provider onStateChange={handleAuthStateChange}>
      <BrowserRouter>
        <Switch>
          <Route path = '/' exact component={PublicPage} />
          <Route path = '/logout' exact component={PublicPage} />
          <Route path = '/public' exact component={PublicPage} />
          <Route path = '/home'  component={PublicPage} />

          <Route path = '/login'  component={UserPage} />
          <Route path = '/main'  component={ProtectedPage} />
          <Route path = '/register'  component={RegisterPage} />
          <Route path = '/signup'  component={RegisterPage} />

          <Route path = '/settings'  component={UserSettings} />
          <Route path = '/documents'  component={Documents} />
          <Route path = '/payments'  component={PaymentsPage} />
          <Route path = '/faq'  component={FaqPage} />
        </Switch>
    </BrowserRouter>
   </Authenticator.Provider>
  );
}