
/**
 * Documents Data for Links on website
 * @returns 
 * 
 *    
 */

export default function DocumentsData () {
    return ( 
        <div className='App'>
            <br></br>
            <h1 className="text-center emphTitle">Documents</h1>
            <p className='whiteFont text-center'>Access important files, forms, and guidelines in our centralized document repository..</p>

        </div>
    );
}
