import DocumentsData from '../section/DocumentsData.js';
import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';
import { Authenticator } from '@aws-amplify/ui-react';
//boostrap components 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Documents = () => {
    return (
      <div className='App'>
        <Banner/>
        <NavMenu/> 
        <Authenticator className='login_container container'  loginMechanisms={['email']}  hideSignUp>
          <Container className='App padding-top'>
            <Row>
              <Col xs={12} md={12} lg={6} xl={6}>
                <div className='App'>
                  <h3 className='text-center emphTitle'>MY DOCUMENTS</h3>
                  <hr></hr>
                  <br></br>
                  <h3 className='text-center emphTitle'>COMING SOON</h3>
                  <hr></hr>
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} xl={6}>
                  <DocumentsData/>
              </Col>
            </Row>
          </Container>  
        </Authenticator>

        <SITELINKS/> 
      </div>
    );
  };