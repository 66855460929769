
import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';

//boostrap components 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const FaqPage = () => {
  
    return (
      <div className='App'>

        <Banner/>
        <NavMenu/> 
        <Container className='App'>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <div className='App empBorder padding text-center'>
                <h1 className='text-center emphTitle'>FAQ Page</h1>
                <p className=' whiteFont'>
                  <i>Page Updated 9/24/2024</i>
                </p>
                <br></br><hr></hr>
                <h4 className='emphTitle text-center'>How do I begin?</h4>
                <p className=' whiteFont'>Please Navigate to the Portal Dashboard and select Create Account / Register Account. 
                  You can also visit the webpage here:  
                  <a className='btn btn-primary' href='/register'>Register</a>
                </p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Unable to View Payments?</h4>
                <p className=' whiteFont'>Please make sure that you are signed up & logged in using your Metro Housing Boston Affiliated email account. 
                  This is the account you provided to Metro Housing Boston when the application for Rent Relief was filed</p>
                <p className=' whiteFont'>Please file a request with <a className='btn btn-primary' href="mailto:portalhelp@mbhp.org?subject=[Update Portal Email]">Portal Help / Technical Support</a>to update your MHB affiliated email account</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Incorrect data submitted when account was created?</h4>
                <p className=' whiteFont'>Please file a request with <a className='btn btn-primary'href="mailto:portalhelp@mbhp.org?subject=[Incorrect Account Data]">Portal Help / Technical Support</a> to edit your account</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Duplicate Payment Data shown on Payments Page?</h4>
                <p className=' whiteFont'>Please file a request with <a className='btn btn-primary' href="mailto:portalhelp@mbhp.org?subject=[Duplicate Payment Data]">Portal Help / Technical Support</a> to edit the invalid payment information</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Why cant I view documents</h4>
                <p className=' whiteFont'>Coming Soon</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Why cant I view messages from Metro Housing Boston</h4>
                <p className=' whiteFont'>Coming Soon</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>How Do I Submit Feedback on the Portal</h4>
                <p className=' whiteFont'>Click here to get started: <a className='btn btn-primary' href="https://forms.office.com/Pages/ResponsePage.aspx?id=J8xXIgxdK0y6AnOQOwjqAHVPPCEmmcRLu9IABP8zZRBUQlFDU0RFQUxRRjdCTzY4NTZQWjVMS09SVC4u">MHB Portal Feedback Form</a> </p>
                <br></br>
                <hr></hr>
              </div>
            </Col>
            <Col xs={12} md={12} lg={12} xl={12}>
                
            </Col>
          </Row>
        </Container>  
        <SITELINKS/> 
      </div>
    );
  };