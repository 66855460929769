
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/
import Container from 'react-bootstrap/Container';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aptLogo from '../images/metrobgcr.jpg';

export default function LoggedOutBanner()
{
    return(
        <div className='whiteFont padding'>                      
            <h2 className='emphTitle2 paddingLeft '>Welcome</h2>
            <hr></hr>
            <br></br>

            <Container>
                <Row>
                    <Col xs={12} md={12}>
                        <img src={aptLogo} className='aptLogo' alt="metroBG.jpg" />
                        <br></br>
                        <br></br>
                        <hr></hr>
                    </Col>
                </Row>
            </Container>

            <Container className='text-center  m_gateway'>
                <Row>
                    <Col xs={12} md={12} >
                        <h4 className='emphTitle'>LOGIN TO VIEW PAYMENTS</h4>
                        <p>
                            Log into this website using your username and password. If you have not created an account, please <a className='btn btn-primary'  href='/login'>Start Today</a>
                        </p> 
                    </Col>
                    <Col xs={12} md={12}>
                        <h4 className='emphTitle'>TRACK ALL PAYMENTS</h4>
                        <p>
                            View payments from Metro Housing Boston which include Section 8, MRVP, and RAFT/HOMEBASE payments. Reach out to accounting@metrohousingboston.org to look into missing or incorrect payments.
                        </p> 
                    </Col>
                    <Col xs={12} md={12}>
                        <h4 className='emphTitle'>CREATE AN ACCOUNT AND LINK PREVIOUS PORTAL ACCOUNT TODAY</h4>
                        <p>
                            Thank you for visiting the Metro Housing Property Management Portal. Please Login using your email address and password or create a new account below.
                        </p> 
                    </Col>

                    <h4 className='paddingBottom'>Looking to get started? </h4>
                    <br></br>
                    <br></br>
                    <a className='whiteFont emphTitle text-center m_register btn btnColor  ' href='/register'>Register Today</a>
                    <br></br>
                    <h1><a className='whiteFont emphTitle padding text-center m_register' href='/register'>(REGISTRATION INSTRUCTIONS)</a></h1>
                    <br></br>
                    <br></br>
                    <hr></hr>
                </Row>
            </Container>
        </div>
    );
}