
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import creationLogo from '../images/icons8-account-100.png';
import confirmLogo from '../images/icons8-received-100.png';
import welcomeLogo from '../images/icons8-log-in-100.png';


export default function RegisterSteps()
{
    return(
        <div className='whiteFont text-center'>   
            <Container>
                <Row>
                    <Col  xs={12} md={12} lg={12} xl={12}>
                        <div className='leftContent text-center'>
                            <hr></hr>
                            <h2 className=''>Registration Steps</h2>
                            <hr></hr>
                            <h1 className='whiteFont emphTitle'>REGISTER STEPS BELOW</h1>
                            <br></br>
                            <h4>Making an account is easy, follow the steps below to get started today</h4>
                            <br></br>
                        </div>              
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} md={4} lg={4} xl={4}>
                        <div className='leftContent'>
                            <h3 className=''>STEP 1</h3>
                            <hr></hr>
                            <h4 className='whiteFont emphTitle'>ACCOUNT CREATION</h4>
                            <hr></hr>
                            <p>Enter your Metro Housing Boston Affiliated Email Address. If you do not know this, please check our FAQ pag for more information</p>
                            <img src={creationLogo} className='aptLogo' alt="mhb-site-logo.png" />
                        </div>              
                    </Col>
                    <Col  xs={12} md={4} lg={4} xl={4}>
                        <div className='leftContent'>
                            <h3 >STEP 2</h3>
                            <hr></hr>
                            <h4 className=' whiteFont emphTitle'>EMAIL CONFIRMATION</h4>
                            <hr></hr>
                            <p>Please confirm your account by entering the PIN that is sent to your MHB affiliated email that was used in the first step</p>
                            
                            <img src={confirmLogo} className='aptLogo' alt="mhb-site-logo.png" />
                        </div>              
                    </Col>
                    <Col  xs={12} md={4} lg={4} xl={4}>
                        <div className='leftContent'>
                            <h3 className=''>STEP 3</h3>
                            <hr></hr>
                            <h4 className=' whiteFont emphTitle'>WELCOME TO MBHP</h4>
                            <hr></hr>
                            <p>Once Logged in, please click on the Payments link to navigate and view all MHB Payments for your properties</p>
                            <br></br>
                            <img src={welcomeLogo} className='aptLogo' alt="mhb-site-logo.png" />
                        </div>              
                    </Col>
                </Row>
            </Container>            
        </div>
    );
}