import Container from 'react-bootstrap/Container';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import React from "react";

export const LoggedInNav = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { push } = useHistory();
  const onButtonClick = async () => {
    await Auth.signOut();
    alert('Signed out');
    push('/public');
  };
    
  if (user) {
    return (
      <div className='App'>
        <Navbar expand='lg' className=' navbar-expand navContainer2'>
          <Container className='container'>
            <Navbar.Toggle />
            <Navbar.Collapse >
              <Nav className=''>
              <Nav.Link href='/home'>PORTAL DASHBOARD</Nav.Link>
              <Nav.Link href='/payments'>PAYMENTS</Nav.Link>
                <NavDropdown title='OPTIONS'>
                <NavDropdown.Item href='/main'>MESSAGES & ALERTS</NavDropdown.Item>
                  <NavDropdown.Item href='/documents'>MY DOCUMENTS</NavDropdown.Item>
                  <NavDropdown.Item href='/settings'>SETTINGS</NavDropdown.Item>
                  <NavDropdown.Item href='/faq'>FREQUENTLY ASKED QUESTIONS</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={onButtonClick}>
                    SIGN OUT
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    )
  } else {
    return (
      <div className='App'>
        <Navbar expand='lg' className=' navbar-expand navContainer2'>
          <Container className='container'>
            <Navbar.Toggle />
            <Navbar.Collapse >
              <Nav className=''>
              <Nav.Link className='registerBTN' href='/register'>REGISTER TODAY</Nav.Link>
              <Nav.Link href='/home'>PORTAL DASHBOARD</Nav.Link>
                <NavDropdown title='FAQ'>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='/login'>
                    LOG IN
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/faq'>FREQUENTLY ASKED QUESTIONS</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    ) 
  }
}

export default LoggedInNav;