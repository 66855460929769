import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import PaymentsData from '../section/PaymentsData.js';
import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';
import { Authenticator } from '@aws-amplify/ui-react';

export const PaymentsPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);
    return (
      <div className='App '>
        <Banner/>
        <NavMenu/> 
        <Authenticator className='login_container container'  loginMechanisms={['email']}  hideSignUp>
          {user && (
              <PaymentsData/>
            )}
          </Authenticator>
        <SITELINKS/> 
      </div>
    );
  }