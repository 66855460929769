//AWS and REACT Libraries 
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import Axios from '../axios.js';

//page components
import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';
import RegisterSteps from '../components/RegisterSteps.js'//used to show the user when they are logged in how to use the website

//boostrap components 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const RegisterPage = () => {
  const findAccountByEmail = async (email) => {
    try {
      const response = await Axios.get(`/account/${email}`);
      if (response.data.success) {
        return response.data.isFound;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  function generateUsername(email) {
    const localPart = email.split('@')[0];
    const timestamp = Date.now(); // Gets the current timestamp
    return `${localPart}${timestamp}`;
}

  return (
    <div className='App'>
      <Banner/>
      <NavMenu/> 
      <Container>
          <Row>
            <Col  xs={12} md={12} lg={12} xl={12}>
              <div className='leftContent'>
                <RegisterSteps/>
              </div>              
            </Col>
            <Col  xs={12} md={12} lg={12} xl={12}>
              <div className='rightContent'> 
                <hr></hr>
                <br></br>
                <h3 className='text-center whiteFont UserNewsDashboard '>Register Today or Log In Now</h3>
                <br></br>
                <hr></hr>
                <br></br>

                <Authenticator className='login_container container' loginMechanisms={['email']} initialState='signUp'
                  components={{
                    SignUp: {
                      FormFields() {
                        return (
                          <>
                            <div className='App emphTitle'>
                              <p className='text-center'>METRO HOUSING BOSTON USER ACCOUNT REGISTRATION FORM </p>
                              <hr></hr>
                            </div>
                            <Authenticator.SignUp.FormFields />
                            {/* Custom fields for given_name and family_name */}
                            <div className='amplify-label'>First name</div>
                            <input
                              type='text'
                              name='given_name'
                              className='amplify-input'
                              placeholder='Please enter your first name'
                            />
                            <div className='amplify-label'>Last name</div>
                            <input
                              type='text'
                              name='family_name'
                              className='amplify-input'
                              placeholder='Please enter your last name'
                            />
                          </>
                        );
                      },
                    },
                    }}
                  services={{
                    async validateCustomSignUp(formData) {
                      if (!formData.given_name) {
                        return {
                          given_name: 'First Name is required',
                        };
                      }
                      if (!formData.family_name) {
                        return {
                          family_name: 'Last Name is required',
                        };
                      }
                      if (!formData.email) {
                        return {
                          email: 'Email is required',
                        };
                      }
                    },
                    async handleSignUp(formData) {
                      console.log(formData);
                      const isFound = await findAccountByEmail(formData?.attributes?.email);
                      if (!isFound) {
                        throw new Error('Email address not found in our system');
                      }
                      // Proceed with the default sign-up process
                      return Auth.signUp({...formData, autoSignIn: { enabled: true }, username: generateUsername(formData?.attributes?.email) });
                    }
                    }}
                    >

                </Authenticator>
              </div>            
            </Col>
          </Row>

        </Container>


      
      <SITELINKS/> 
    
    </div>
  );
};