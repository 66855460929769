import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';
import { setAuthToken } from '../axios.js';

//boostrap components 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//pages 

import { Authenticator } from '@aws-amplify/ui-react';
import { Switch, Link, useRouteMatch, Route } from 'react-router-dom';
//user or admin settings below

import { UserPage } from './UserPage.js';
import { AdminPage } from './AdminPage.js';


export const UserSettings = () => {
  const { path, url } = useRouteMatch();

  return (

    <div className='App'>
        <Banner/>
        <NavMenu/> 

        <Container className='App padding-top'>
          <Row>
            <Col  xs={12} md={12} lg={6} xl={6}>
              <div className='rightContent '> 
                <Authenticator className='login_container container' loginMechanisms={['email']} hideSignUp 
                  components={{
                    SignUp: {
                      FormFields() {
                        return (
                          <>
                            <Authenticator.SignUp.FormFields />
                            {/* Custom fields for given_name and family_name */}
                            <div><label>First name</label></div>
                            <input
                              type='text'
                              name='given_name'
                              placeholder='Please enter your first name'
                            />
                            <div><label>Last name</label></div>
                            <input
                              type='text'
                              name='family_name'
                              placeholder='Please enter your last name'
                            />
                            <div><label>Email</label></div>
                            <input
                              type='text'
                              name='email'
                              placeholder='Please enter a valid email'
                            />


                          </>
                        );
                      },
                    },
                    }}
                  services={{
                    async validateCustomSignUp(formData) {
                      if (!formData.given_name) {
                        return {
                          given_name: 'First Name is required',
                        };
                      }
                      if (!formData.family_name) {
                        return {
                          family_name: 'Last Name is required',
                        };
                      }
                      if (!formData.email) {
                        return {
                          email: 'Email is required',
                        };
                      }
                    },
                    }}
                    >
                  {({ user }) => {
                    const session = user.getSignInUserSession();
                    if (!session) throw new Error('SignInSession is empty!');
                    const accessToken = session.getAccessToken();
                    const idToken = session.getIdToken();
                    setAuthToken(idToken.getJwtToken());
                    const groups = accessToken.payload['cognito:groups'] || [];
                    const isAdmin = groups.includes('admin');
                    return (
                      <div className='App '>
                        <h3 className='text-center emphTitle'>User Data</h3>
                        <hr></hr>
                        <div className='whiteFont'>
                          <p>Property Name: {user.username}</p>
                          <br></br>
                          <p>First Name: {user.attributes.given_name}</p>
                          <br></br>
                          <p>Last Name: {user.attributes.family_name}</p>
                          <br></br>
                          <p>Email Adress: {user.attributes.email}</p>
                          <br></br>
                        </div>
                        <div className='whiteFont remove'>
                          <h5>{`Logged In ${
                            groups.length > 0 ? groups : 'Account:'
                          }`}</h5>
                          <ul>
                            <li>
                              <br></br>
                              <Link to={`${url}`}>{user.username}</Link>
                            </li>
                            {isAdmin && (
                              <li>
                                <Link to={`${url}/admin`}>Admin</Link>
                              </li>
                            )}
                          </ul>
                        </div>
                        <hr />
                        <Switch>
                          <Route path={`${'/main'}`} exact>
                            <UserPage />
                          </Route>
                          <Route path={`${path}/admin`}>
                            <AdminPage />
                          </Route>
                        </Switch>
                      </div>
                    );
                  }}
                </Authenticator>
              </div>            
            </Col>
            
            <Col  xs={12} md={12} lg={6} xl={6}>
              <div className='leftContent  '>
                <h4 className='emphTitle text-center'>FAQ</h4>
                <hr></hr>
                <br></br>

                <h4 className='emphTitle text-center'>Unable to View Payments?</h4>
                <p className=' whiteFont'>Please make sure that you are signed up & logged in using your Metro Housing Boston Affiliated email account. 
                  This is the account you provided to Metro Housing Boston when the application for Rent Relief was filed</p>
                <p className=' whiteFont'>Please file a request with <a className='btn btn-primary'  href="mailto:portalhelp@mbhp.org?subject=[Update Portal Email]">Portal Help / Technical Support</a>to update your MHB affiliated email account</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Incorrect data submitted when account was created?</h4>
                <p className=' whiteFont'>Please file a request with <a className='btn btn-primary'  href="mailto:portalhelp@mbhp.org?subject=[Incorrect Account Data]">Portal Help / Technical Support</a> to edit your account</p>
                <br></br>
                <hr></hr>

                <h4 className='emphTitle text-center'>Duplicate Payment Data shown on Payments Page?</h4>
                <p className=' whiteFont'>Please file a request with <a className='btn btn-primary'  href="mailto:portalhelp@mbhp.org?subject=[Duplicate Payment Data]">Portal Help / Technical Support</a> to edit the invalid payment information</p>
                <br></br>
                <hr></hr>
                
                
              </div>              
            </Col>
          </Row>

        </Container>
      
        <SITELINKS/> 

    </div>
  );
};