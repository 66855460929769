
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/
import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import LoggedInBanner from '../components/LoggedInBanner.js';
import LoggedOutBanner from '../components/LoggedOutBanner.js';

export const LoginSteps = () => 
{
    const { user } = useAuthenticator((context) => [context.user]);


    if (user) {
        return (
            <div className='padding'>
                <LoggedInBanner/>
            </div>
        )
      } else {
        return (
        
            <div className='whiteFont padding'>                
                <LoggedOutBanner/>
            </div>
        ) 
      }
}

export default LoginSteps;
      
 