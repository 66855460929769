import metrologo from '../images/metro-logo-footer-white-.png';
import AlertF from './AlertF.js';
export default function Footer() {
    return (
         <>  
            <div className='text-center text-lg-start Footer'>
  
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>


                </section>

                <section className=''>
                    <div className='container text-center text-md-start mt-5'>
                        <div className='row mt-3'>

                            <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>

                                <h6 className='text-uppercase fw-bold mb-4'>
                                <i  className='fas fa-gem me-3 navbar-brand '></i> Metro Housing Boston
                                </h6>
                                <a href='/home' >
                                    <img src={metrologo} className='mLogo logoFooter' alt='mhb-site-logo.png' />
                                </a>                            
                            
                            </div>

                            <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>

                                <h6 className='text-uppercase fw-bold mb-4'>
                                    IT Support
                                </h6>
                                <p>
                                    <a href='mailto:portalhelp@mbhp.org?subject=[Technical Support]' className='text-reset'>Technical Support</a>
                                </p>
                                <p>
                                    <a href='mailto:gateway@metrohousingboston.org?subject=[Gateway Support]' className='text-reset'>Gateway Support</a>
                                </p>
                                <p>
                                    <a href='mailto:portalhelp@mbhp.org?subject=[Report Duplicate Payments]' className='text-reset'>Report Duplicate Payments</a>
                                </p>
                                <p>
                                    <a target='_blank' rel='noreferrer' href='https://airtable.com/shrzMcypjWPdZaeLL' className='text-reset'>Voucher Increase Support</a>
                                </p>
                            </div>

                            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>

                                <h6 className='text-uppercase fw-bold mb-4'>
                                    Metro Links
                                </h6>
                                <p>
                                    <a target='_blank' rel='noreferrer' href='https://metrohousingboston.zendesk.com/hc/en-us' className='text-reset'>Leased Housing Help Center</a>
                                </p>
                                <p>
                                    <a target='_blank' rel='noreferrer' href='https://applyhousinghelp.mass.gov/s/?language=en_US&LanguageCode=en_US' className='text-reset'>Upgrade your Raft Application</a>
                                </p>
                                <p>
                                    <a href='/signup' className='text-reset'>Sign-up</a>
                                </p>
                                <p>
                                    <a target='_blank' rel='noreferrer' href='https://forms.office.com/Pages/ResponsePage.aspx?id=J8xXIgxdK0y6AnOQOwjqAHVPPCEmmcRLu9IABP8zZRBUQlFDU0RFQUxRRjdCTzY4NTZQWjVMS09SVC4u' className='text-reset'>Submit Feedback</a>
                                </p>
                            </div>
                            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>Contact Us</h6>
                                <p>
                                    <i className='fas fa-home me-3'></i>
                                    1411 Tremont Street
                                    Boston, MA 02120-3401
                                </p>
                                <p>
                                    <i className='fas fa-envelope me-3'></i>
                                    E-mail: info@metrohousingboston.org
                                </p>
                                <p><i className='fas fa-phone me-3'></i> Phone: 617.859.0400</p>
                                <p><i className='fas fa-print me-3'></i> Toll-Free: 800.272.0990</p>
                            </div>
                        </div>

                    </div>
                </section>



                <div className='text-center p-4'>
                    © 2023 Copyright:
                  <a className='text-reset ' href='https://www.metrohousingboston.org/'> Metro Housing Boston</a>
                </div>

            </div>

            <div className='Banner'>
                <AlertF/>
            </div>
        </> 
    )
};