
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/

import Container from 'react-bootstrap/Container';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function LoggedInBanner()
{
    const { user } = useAuthenticator((context) => [context.user]);

    return(
        <div className='whiteFont padding'>                
            <h2 className='emphTitle'>Logged In User: <p className='whiteFont'> {user.attributes.given_name}  {user.attributes.family_name} </p></h2>
            <hr></hr>
            <br></br>

            <Container>
                <Row>
                    <Col className='loggedInBanner text-center' xs={12} md={12}>
                        <h4 className='emphTitle'>CLICK ON PAYMENTS LINK TO VIEW PAYMENTS</h4>
                        <p>
                            Use this link or click on Payments above in the navigation menu to view payments <a className='btn btn-primary'  href='/payments'>VIEW MY PAYMENTS</a>
                        </p> 
                        <br></br>
                        <br></br>
                        <h4 className='paddingBottom'>Questions or Comments?</h4>
                        <br></br>
                        <br></br>
                        <a className='whiteFont emphTitle text-center m_register btn btnColor' href='/faq'>VISIT OUR FAQ PAGE!</a>
                        <br></br>
                        <br></br>
                        <br></br>
                        <hr></hr>
                    </Col>
                </Row>
            </Container>

            <Container className='text-center  m_gateway'>
                <Row>

                </Row>
            </Container>
        </div>
    );
}