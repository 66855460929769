
//Website UI Components 
import NavMenu from '../section/LoggedInNav.js';

import LoginSteps from '../components/LoginSteps.js';
import AWSLoginSection from '../components/aws_login_component.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';


//boostrap resources 

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



/*Pending Things to Add

Instructions Component 
News Announcements Component
Previous Announcements Component 
Extra Information Component (how to use the site) 


AWS LOGIN SECTION IN RIGHT CONTENT DIV WILL BE MOVED TO ITS OWN FILE CALLED AWS_Login_Component

*/


export const PublicPage = () => {
    return (
      <div className='App'>
        <Banner/>

        <NavMenu/>

        <div className='publicContent'>
          <Row>
            <Col xs={12} md={12} lg={12} xl={8}>
              <div className='leftContent'>
                <LoginSteps/>
              </div>              
            </Col>
            <Col xs={12} md={12} lg={12} xl={4}>
              <div className='rightContent'> 
                <AWSLoginSection/>
              </div>            
            </Col>
          </Row>

        </div>

        <SITELINKS/>
      </div>
    );
  };